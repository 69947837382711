import BaseApi from "./base-api";
import { objToQuerystring } from "../shared/helpers/querystring-helper";
import { golferId, getGolferId } from "../shared/helpers/api-helper";
import { defaultPagination } from "../shared/variables/pagination";
import ScoreType from "../shared/variables/stats-score-type";
import moment from "moment";
import { getUserGhinNo } from "../shared/helpers/user-helper";
import { getScoreAverageFilter } from "../shared/helpers/scores-helper";

class ScoresApi extends BaseApi {
  apiUrl = `${this.baseUrl}/api/v1/scores`;

  getRecentScores = (offset, limit, validatedOnly = undefined) =>
    this.getScores(
      undefined,
      undefined,
      validatedOnly,
      offset,
      limit,
      false,
      false
    );

  getStatsScores = (
    filter,
    offset,
    onlyStats,
    noPagination = false,
    extraParams = undefined
  ) =>
    this.getScores(
      undefined,
      undefined,
      filter,
      offset,
      undefined,
      onlyStats,
      noPagination,
      extraParams
    );

  getScores = (
    ghinNumber = null,
    number_of_holes = null,
    filter = null,
    offset = defaultPagination.OFFSET,
    limit = defaultPagination.PER_PAGE,
    onlyStats,
    noPagination,
    extraParams = undefined
  ) => {
    const url =
      filter && filter === ScoreType.REVISION
        ? `${this.baseUrl}/api/v1/golfers/${getGolferId(
            ghinNumber
          )}/revision_scores.json`
        : `${this.apiUrl}.json`;
    let params = { ...golferId(ghinNumber) };
    if (!noPagination) {
      if (!onlyStats) params = { ...params, offset, limit };
    }

    if (number_of_holes !== null) {
      params = { ...params, number_of_holes };
    }
    if (filter) {
      const dateFormat = "YYYY-MM-DD";
      if (filter === ScoreType.BY_COURSE) {
        params.limit = 20;
      } else if (filter === ScoreType.YEAR) {
        params.from_date_played = moment().startOf("year").format(dateFormat);
        params.to_date_played = moment().format(dateFormat);
      } else if (filter === ScoreType.PAST_YEAR) {
        params.from_date_played = moment()
          .subtract(1, "year")
          .startOf("year")
          .format(dateFormat);
        params.to_date_played = moment()
          .subtract(1, "year")
          .endOf("year")
          .format(dateFormat);
      } else if (filter === ScoreType.TWO_YEARS_AGO) {
        params.from_date_played = moment()
          .subtract(2, "year")
          .startOf("year")
          .format(dateFormat);
        params.to_date_played = moment()
          .subtract(2, "year")
          .endOf("year")
          .format(dateFormat);
      } else if (filter === ScoreType.THREE_YEARS_AGO) {
        params.from_date_played = moment()
          .subtract(3, "year")
          .startOf("year")
          .format(dateFormat);
        params.to_date_played = moment()
          .subtract(3, "year")
          .endOf("year")
          .format(dateFormat);
      } else if (filter === ScoreType.MONTHS_12) {
        params.from_date_played = moment().subtract(1, "y").format(dateFormat);
        params.to_date_played = moment().format(dateFormat);
      } else if (filter === ScoreType.TOURNAMENT) {
        params.score_types = "T";
        params.from_date_played = moment().subtract(2, "y").format(dateFormat);
        params.to_date_played = moment().format(dateFormat);
      } else if (filter === ScoreType.BY_COURSE_ID_AND_TEE_SET_ID) {
        if (extraParams) {
          params = { ...params, ...extraParams };
        }
        params.from_date_played = moment().subtract(2, "y").format(dateFormat);
        params.to_date_played = moment().format(dateFormat);
      }
      params.statuses = "Validated";
    }
    // extraParams
    if (
      onlyStats &&
      filter !== ScoreType.RECENT_AND_REVISION_SCORES &&
      filter !== ScoreType.BY_COURSE
    ) {
      params.only_with_stats = true;
    }
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getGolferScores = (ghinNumber, onlyStats = false, extraParams = {}) => {
    const params = extraParams;
    if (onlyStats) {
      params.only_with_stats = true;
    }
    const url = `${this.baseUrl}/api/v1/golfers/${getGolferId(
      ghinNumber
    )}/scores.json`;
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  confirmScore = (scoreId, golfer_id) => {
    const url = `${this.apiUrl}/${scoreId}/confirm.json`;

    const params = {
      ...golfer_id,
    };

    return this.httpPost(`${url}?${objToQuerystring(params)}`);
  };

  postAdjustedScore = (params) => {
    const url = `${this.apiUrl}/adjusted.json`;

    return this.httpPost(`${url}?${objToQuerystring(params)}`);
  };

  post18h9and9Score = (params) => {
    const url = `${this.apiUrl}/18h9and9.json`;

    return this.httpPost(`${url}?${objToQuerystring(params)}`);
  };

  postHbhScore = (params) => {
    const url = `${this.apiUrl}/hbh.json`;

    return this.httpPost(url, params);
  };

  postEditRoundStats = (scoreId, params) => {
    const url = `${this.apiUrl}/${scoreId}/statistics.json`;
    return this.httpPatch(url, params);
  };

  deleteRoundStats = (scoreId, params) => {
    const url = `${this.apiUrl}/${scoreId}/statistics.json`;
    return this.httpDelete(url, params);
  };

  getChildScores = (scoreId, golferId = null) => {
    const url = `${this.apiUrl}/${scoreId}/get_child_scores.json`;
    const params = {
      golfer_id: golferId || getUserGhinNo(),
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getMaximumHoleScores = (teeSetId, teeSetSide) => {
    const url = `${this.baseUrl}/api/v1/maximum_hole_scores.json`;
    let params = {
      golfer_id: getUserGhinNo(),
      tee_set_id: teeSetId,
      tee_set_side: teeSetSide,
      include_altered_tees: true,
    };
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getScoreCourseDetails = (courseId) => {
    const url = `${this.baseUrl}/api/v1/crsCourseMethods.asmx/GetCourseDetails.json`;
    const params = { courseId: courseId, include_altered_tees: true };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getAverageScores = (filter, course_id, tee_set_id) => {
    const url = `${
      this.baseUrl
    }/api/v1/golfers/${getUserGhinNo()}/statistics.json`;
    const params = { filter: getScoreAverageFilter(filter) };
    if (course_id) params.course_id = course_id;
    if (tee_set_id) params.tee_set_id = tee_set_id;
    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getAverageScorePerHole = (golferId, teeSetId) => {
    const url = `${this.baseUrl}/api/v1/average_hole_scores.json`;

    let params = {
      golfer_id: golferId,
      tee_set_id: teeSetId,
    };

    return this.httpGet(`${url}?${objToQuerystring(params)}`);
  };

  getShareRoundSummary = (scoreId) => {
    const baseApiUrl = `${this.baseUrl}/api/v1`;
    const url = `${baseApiUrl}/scores/${scoreId}/share_round_summary.json`;

    return this.httpGet(url);
  };
}

export default new ScoresApi();
