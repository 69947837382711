import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { overlayModalStyle } from "../../../shared/helpers/ui-helper";

class YesNoModal extends Component {
  componentDidMount = () => Modal.setAppElement(this.props.appElementName);

  render() {
    const { isOpen, onAbort, children } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        style={overlayModalStyle}
        onAfterOpen={this.props.onAfterOpen}
        className="modal-dialog"
        contentLabel={this.props.contentLabel}
      >
        <div className="modal-content">
          <button className="modal_close" onClick={onAbort} aria-label="Close">
            ✕
          </button>
          <div className="modal-body">
            {children}
            <div className="actions centered">{this.renderActions()}</div>
          </div>
        </div>
      </Modal>
    );
  }

  renderActions = () => {
    const { reversedOrder, isNoVisible, isYesVisible } = this.props;

    if (reversedOrder === true) {
      return (
        <Fragment>
          {isYesVisible ? this.renderYesButton() : null}
          {isNoVisible ? this.renderNoButton() : null}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {isNoVisible ? this.renderNoButton() : null}
          {isYesVisible ? this.renderYesButton() : null}
        </Fragment>
      );
    }
  };

  renderNoButton = () => (
    <button
      className={`btn ${
        this.props.reversedHighlight ? "fill cardinal" : "outline blue"
      }`}
      onClick={this.props.onAbort}
    >
      {this.props.noTitle}
    </button>
  );

  renderYesButton = () => (
    <button
      className={`btn ${
        this.props.reversedHighlight ? "outline blue" : "fill cardinal"
      }`}
      onClick={this.props.onConfirm}
    >
      {this.props.yesTitle}
    </button>
  );
}

YesNoModal.defaultProps = {
  reversedOrder: false,
  reversedHighlight: false,
  yesTitle: "YES",
  isYesVisible: true,
  noTitle: "NO",
  isNoVisible: true,
  onAbort: () => {},
  onConfirm: () => {},
  appElementName: "body",
};

YesNoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAbort: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  yesTitle: PropTypes.string.isRequired,
  noTitle: PropTypes.string.isRequired,
  isYesVisible: PropTypes.bool.isRequired,
  isNoVisible: PropTypes.bool.isRequired,
  reversedOrder: PropTypes.bool.isRequired,
  reversedHighlight: PropTypes.bool.isRequired,
  appElementName: PropTypes.string.isRequired,
};

export default YesNoModal;
