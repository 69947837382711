import { store } from "../store/index";
import moment from "moment";

class Pendo {
  setData() {
    let loginReducer = store.getState().authReducer.loginReducer;
    if (loginReducer.subscription != null && loginReducer.free_trial != null) {
      if (process.env.REACT_APP_PENDO_API_KEY) {
        if (window.pendo && loginReducer.userStatus === "Logged In") {
          try {
            if (window.pendo?.isReady())
              window.pendo.updateOptions(this.initializeData(loginReducer));
            else window.pendo.initialize(this.initializeData(loginReducer));
          } catch (e) {
            window.pendo.initialize(this.initializeData(loginReducer));
          }
        }
      }
    }
  }

  initializeData(loginReducer) {
    const golfer = loginReducer.user;
    const user = loginReducer.golfers[0];
    const guardian_id = loginReducer.guardianId;
    const golfer_creation_date = loginReducer.golfer_creation_date;
    const subscription = loginReducer.subscription;
    const free_trial = loginReducer.free_trial;

    let golfer_club = null;
    let user_data = null;
    if (user === null || user === undefined) {
      user_data = {
        id: guardian_id,
        non_golfing_guardian: true,
        email: null,
        name: null,
        gender: null,
        age: null,
        handicap_index: null,
        status: "Active",
        golf_association_id: null,
        club_id: null,
      };
      golfer_club = {
        golf_association_id: golfer.golf_association_id,
        club_id: golfer.club_id,
      };
    } else {
      user_data = {
        id: user.ghin_number,
        non_golfing_guardian:
          guardian_id === null || guardian_id === undefined ? null : false,
        email: user.email,
        name: user.player_name,
        gender: user.gender,
        age: this.getAge(user.date_of_birth),
        handicap_index: user.display,
        status: user.status,
        golf_association_id: user.primary_golf_association_id,
        club_id: user.primary_club_id,
      };
      golfer_club = {
        golf_association_id:
          golfer.ghin_number === user.ghin_number
            ? golfer.primary_golf_association_id
            : golfer.golf_association_id,
        club_id:
          golfer.ghin_number === user.ghin_number
            ? golfer.primary_club_id
            : golfer.club_id,
      };
    }

    const visitor = {
      id: golfer.ghin_number,
      email: golfer.email,
      name: golfer.player_name,
      gender: golfer.gender,
      age: this.getAge(golfer.date_of_birth),
      handicap_index: golfer.display,
      status: golfer.status,
      ...golfer_club,
    };

    const account = {
      ...user_data,
      golfer_creation_date: moment(golfer_creation_date).format("YYYY-MM-DD"),
      active_subscription: subscription.active,
      subscription_app_type: subscription.subscription_app_type,
      subscription_start_date: moment(
        subscription.initial_subscription_date
      ).format("YYYY-MM-DD"),
      trial_18hole_complete: free_trial["18hole_complete"],
      trial_18hole_date_completed: moment(
        free_trial["18hole_date_completed"]
      ).format("YYYY-MM-DD"),
      trial_9hole_complete: free_trial["9hole_complete"],
      trial_9hole_date_completed: moment(
        free_trial["9hole_date_completed"]
      ).format("YYYY-MM-DD"),
    };

    return { visitor: visitor, account: account };
  }

  getAge(date) {
    if (
      date === null ||
      date === undefined ||
      date === "" ||
      date === "0001-01-01"
    )
      return null;
    else {
      return moment().diff(date, "years");
    }
  }
}

export default new Pendo();
