import React, { Component } from "react";
import ScoresApi from "../../../../services/scores-api";
import EndOfYearCampaignModal from "../../../end-of-year-campaign/components/EndOfYearCampaignModal";
import Loader from "../../../../shared/components/loaders/Loader";

class ShareRoundSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareRoundSummaryUrl: "",
      isModalOn: false,
    };
  }
  getShareRoundSummary = (scoreId) => {
    ScoresApi.getShareRoundSummary(scoreId).then((response) => {
      this.setState({
        shareRoundSummaryUrl: response.data.share_round_summary_url,
      });
    });
  };

  closeModal = () => this.setState({ isModalOn: false });

  openModal = (event) => {
    event.preventDefault();
    const { shareRoundSummaryUrl } = this.state;
    const { score } = this.props;
    if (shareRoundSummaryUrl === "") {
      this.getShareRoundSummary(score.id);
    }
    this.setState({ isModalOn: true });
  };

  render = () => (
    <div>
      <div className="columns share-round_summary__container">
        <div className="row share-round_summary__row">
          <div className="col auto h-centered">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              className="btn fill blue share-round_summary__button"
              onClick={this.openModal}
            >
              Download round summary
            </a>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <EndOfYearCampaignModal
        isOpen={this.state.isModalOn}
        onClose={this.closeModal}
      >
        {this.state.shareRoundSummaryUrl != "" ? (
          <img
            src={this.state.shareRoundSummaryUrl}
            alt="Share Round Summary"
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <Loader />
        )}
      </EndOfYearCampaignModal>
    </div>
  );
}

export default ShareRoundSummary;
