import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBxgTOAWxiud0HuaE5tN-5NTlzFnrtyz-I",
  authDomain: "ghin-mobile-app.firebaseapp.com",
  databaseURL: "https://ghin-mobile-app.firebaseio.com",
  projectId: "ghin-mobile-app",
  storageBucket: "ghin-mobile-app.appspot.com",
  messagingSenderId: "884417644529",
  appId: "1:884417644529:web:47fb315bc6c70242f72650",
  measurementId: "G-FEDPD152HH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);

export function setEvent(user, event_name, tag_key, tag_value) {
  if (user) {
    setUserProperties(firebaseAnalytics, {
      OS: "ghin.com",
      AssociationName: user.association_name,
      ClubName: user.club_name,
      HandicapIndex: user.handicap_index,
    });
  }
  switch (tag_key) {
    case "Logo_Display":
      logEvent(firebaseAnalytics, event_name, {
        Logo_Display: tag_value,
      });
      break;
    case "Logo_Click":
      logEvent(firebaseAnalytics, event_name, {
        Logo_Click: tag_value,
      });
      break;
    case "Share_Destination":
      logEvent(firebaseAnalytics, event_name, {
        Share_Destination: tag_value,
      });
      break;
    case "Year":
      logEvent(firebaseAnalytics, event_name, {
        Year: tag_value,
      });
      break;
  }
}
