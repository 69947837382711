import { fetchHomeCourses, clearHomeCourses } from "../actions";
import {
  resetHandicapCalculator,
  handicapCalculatorRemoveCourse,
} from "../../handicap-calculator/actions";
import { resetFollowedGolfers } from "../../golfers/actions";
import { resetProductAlert } from "../../product-alert/actions";
import { routes } from "../../../shared/routes/index";
import Navigate from "../../../shared/navigation/navigate";
import { store } from "../../../shared/store";
import { rememberMe } from "../../../shared/helpers/local-storage";
import {
  fetchGolferProfile,
  logoutGolferProfile,
  fetchContactAssociations,
} from "../../profile/actions";
import { fetchCountries } from "../../golfers/actions";
import { mergeMinorGolfer } from "../../../shared/helpers/user-helper";
import GolferApi from "../../../services/golfer-api";

export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const PERSIST_INITIAL_LOGIN = "PERSIST_INITIAL_LOGIN";

export const LOGOUT = "LOGOUT";
export const RESET_FORM = "RESET_FORM";

//Terms
export const WAIT_TO_ACCEPT_TERMS = "WAIT_TO_ACCEPT_TERMS";
export const USER_ACCEPTED_TERMS = "USER_ACCEPTED_TERMS";
export const USER_DENIED_TERMS = "USER_DENIED_TERMS";

//Select golfers
export const WAIT_TO_SELECT_GOLFER = "WAIT_TO_SELECT_GOLFER";
export const SELECT_GOLFER = "SELECT_GOLFER";
export const UPDATE_MINORS = "UPDATE_MINORS";
export const START_FETCH = "START_FETCH";
export const STOP_FETCH = "STOP_FETCH";

export const updateMinors = (payload) => ({
  type: UPDATE_MINORS,
  payload,
});

export const login = (password, emailOrGhin, remember_me) => ({
  type: LOGIN,
  payload: { password, emailOrGhin, remember_me },
});

export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

const logout = () => ({ type: LOGOUT });

export const resetForm = () => ({ type: RESET_FORM });

export const waitForAcceptTerms = (payload) => ({
  type: WAIT_TO_ACCEPT_TERMS,
  payload,
});

export function userAcceptedTerms() {
  return (dispatch) => {
    dispatch({ type: USER_ACCEPTED_TERMS });
    dispatch(logInUser());
  };
}

export const userDeniedTerms = () => ({ type: USER_DENIED_TERMS });

export const persistInitialLogin = (payload) => ({
  type: PERSIST_INITIAL_LOGIN,
  payload,
});

export const waitToSelectGolfer = () => ({ type: WAIT_TO_SELECT_GOLFER });

export function selectGolfer(payload) {
  return (dispatch) => {
    dispatch({ type: SELECT_GOLFER, payload });
    if (payload.status !== "Archived") {
      dispatch(fetchGolferProfile());
      dispatch(fetchHomeCourses());
      dispatch(fetchCountries());
      dispatch(fetchContactAssociations());
    }
  };
}

export function loadMinorGolfer(payload) {
  return (dispatch) => {
    dispatch({ type: START_FETCH });
    GolferApi.searchMinorGolfer(payload.id)
      .then((response) => {
        dispatch({ type: STOP_FETCH });
        dispatch(selectGolfer(mergeMinorGolfer(response.data.golfers[0])));
      })
      .catch(() => {
        dispatch({ type: STOP_FETCH });
        dispatch(completeLogout()).then(() => {
          Navigate.resetToPath(routes.login.path);
        });
      });
  };
}

function logInUser() {
  return (dispatch) => {
    const { minors, golfers } = store.getState().authReducer.loginReducer;
    if (minors && minors.length > 0) {
      if (minors.length < 2 && golfers.length === 0) {
        dispatch(loadMinorGolfer(minors[0]));
      } else {
        dispatch(waitToSelectGolfer());
      }
    } else {
      dispatch(selectGolfer(golfers[0]));
    }
  };
}

export function loginSuccess(response, payloadLogin) {
  rememberMe(payloadLogin);
  return (dispatch) => {
    const { golfer_user } = response;
    if (golfer_user) {
      dispatch(persistInitialLogin(response.golfer_user));
      if (!golfer_user.golfer_user_accepted_terms) {
        dispatch(waitForAcceptTerms(golfer_user));
      } else {
        dispatch(logInUser());
      }
    }
  };
}

export function checkApiError(error = null) {
  return (dispatch) => {
    if (error && error.message && error.message.includes("401")) {
      dispatch(completeLogout()).then(() => {
        Navigate.resetToPath(routes.login.path);
      });
    } else {
      dispatch({ type: "" });
    }
  };
}

export function completeLogout() {
  return (dispatch) => {
    GolferApi.logout();
    return new Promise((resolve) => {
      dispatch(handicapCalculatorRemoveCourse());
      dispatch(resetHandicapCalculator());
      dispatch(resetFollowedGolfers());
      dispatch(clearHomeCourses());
      dispatch(resetProductAlert());
      dispatch(logoutGolferProfile());
      dispatch(logout());
      setTimeout(() => {
        resolve();
      }, 250);
    });
  };
}
