import jobStatus from "../../../shared/variables/job-status";
import { defaultPagination } from "../../../shared/variables/pagination";
import {
  FETCH_STATS_SCORES,
  FETCH_STATS_SCORES_NEXT,
  FETCH_STATS_SCORES_SUCCESS,
  FETCH_STATS_SCORES_FAILED,
  FETCH_STATS_SCORES_UPDATE,
  FETCH_STATS_COMBINED_SCORES,
  FETCH_STATS_COMBINED_SCORES_FAILED,
  FETCH_STATS_COMBINED_SCORES_SUCCESS,
} from "../actions";

const initialState = {
  scores: [],
  recentScores: [],
  revisionScores: [],
  scores9Holes: [],
  offset: defaultPagination.OFFSET,
  fetchNextStatus: false,
  jobStatus: jobStatus.NOT_STARTED,
  additionalData: undefined,
};

const getOffset = (currentScores, newScores) => {
  if (
    newScores === null ||
    newScores.length === 0 ||
    newScores.length < defaultPagination.PER_PAGE
  ) {
    return defaultPagination.OFFSET;
  }

  const total = [...currentScores, ...newScores];
  return currentScores.length === 0 ? defaultPagination.PER_PAGE : total.length;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STATS_SCORES:
      return {
        ...state,
        scores: [],
        offset: defaultPagination.OFFSET,
        jobStatus: jobStatus.PROCESSING,
        scores9Holes: [],
      };
    case FETCH_STATS_SCORES_NEXT:
      return {
        ...state,
        fetchNextStatus: true,
        jobStatus: jobStatus.SUCCESS,
      };
    case FETCH_STATS_SCORES_SUCCESS:
      return {
        ...state,
        fetchNextStatus: false,
        scores: [...state.scores, ...payload.scores],
        offset: payload.onlyStats ? 0 : getOffset(state.scores, payload.scores),
        additionalData: payload.additionalData,
        jobStatus: jobStatus.SUCCESS,
        scores9Holes: [],
      };
    case FETCH_STATS_SCORES_FAILED:
      return {
        ...state,
        jobStatus: jobStatus.FAILED,
      };
    case FETCH_STATS_SCORES_UPDATE:
      return {
        ...state,
        scores: state.scores.map((x) =>
          Number(x.id) === Number(payload.updatedScore.id)
            ? payload.updatedScore
            : x
        ),
      };
    case FETCH_STATS_COMBINED_SCORES:
    case FETCH_STATS_COMBINED_SCORES_FAILED:
      return {
        ...state,
        scores: [],
        jobStatus: jobStatus.PROCESSING,
        scores9Holes: [],
        offset: defaultPagination.OFFSET,
      };
    case FETCH_STATS_COMBINED_SCORES_SUCCESS:
      return {
        ...state,
        scores: [
          ...payload.recent_scores.scores,
          ...payload.revision_scores.scores,
        ],
        additionalData: payload.score_history_stats,
        scores9Holes: payload["9_hole_score"].scores,
        recentScores: payload["recent_scores"].scores,
        revisionScores: payload["revision_scores"].scores,
        jobStatus: jobStatus.SUCCESS,
        offset: defaultPagination.OFFSET,
      };
    default:
      return state;
  }
};
